@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
    --red30: #6ecaf5;
    --black80: #222831;
    --gray10: #DDDDDD;
    --blue-gray: #30475e;
    --orange: #f8920cff;
    --blue30: #21abd4;
    --red80: #DD3444;
    --green20: #8BE78B;
    --padding-sides: 5rem;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input, select {
    width: 100%;
    padding: 15px;
    border-radius: 0.4rem;
    border: 1px solid gray;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}
